import { StyleProvider } from "@ant-design/cssinjs";
import { ChakraProvider } from "@chakra-ui/react";
import { UIEnvironmentService } from "@condoit-apps/config/src";
import { withSentry } from "@sentry/remix";
import React from "react";
import type { LinksFunction, LoaderFunctionArgs, MetaFunction } from "react-router";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "react-router";
import { ErrorBoundary } from "./components/SomethingWentWrong";
import toggleSelectorStyles from "./components/ToggleSelector/toggleSelector.css?url";
import { EnvironmentProvider } from "./contexts/environmentContext";
import globalsStyles from "./styles/globals.css?url";
import { chakraTheme } from "./theme/chakraTheme";

// export const headers = () => ({
//   "Cache-Control": "private, max-age=3600, must-revalidate", // Cache for 1 hour
// });

export const meta: MetaFunction = () => {
  return [{ title: "Condoit" }, { name: "description", content: "Condoit" }];
};

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },

  { rel: "stylesheet", href: toggleSelectorStyles },

  { rel: "stylesheet", href: globalsStyles }, //has tailwind styles
];
const DEFAULT_COLOR_MODE: "dark" | "light" | null = "light";

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const env = new UIEnvironmentService();

  return { env };
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useLoaderData<typeof loader>();
  const env = data?.env || {};

  return (
    <html
      lang="en"
      {...(DEFAULT_COLOR_MODE && {
        "data-theme": DEFAULT_COLOR_MODE,
        style: { colorScheme: DEFAULT_COLOR_MODE },
      })}
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body
        {...(DEFAULT_COLOR_MODE && {
          className: `chakra-ui-${DEFAULT_COLOR_MODE}`,
        })}
      >
        <StyleProvider hashPriority="high">
          <ChakraProvider theme={chakraTheme}>
            <EnvironmentProvider env={env}>{children}</EnvironmentProvider>
          </ChakraProvider>
        </StyleProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export { ErrorBoundary };

export function App() {
  return <Outlet />;
}

export default process.env.NODE_ENV === "development"
  ? App
  : withSentry(App, {
      wrapWithErrorBoundary: false,
    });
